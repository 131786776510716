<template>
    <v-row class="single-page ma-0">
        <v-col class="single-page-image" id="camera-image-top">
            <v-img :src="showRef ? refImage : image"
                    ref="cameraPicture"
                    class="system-image-full"
                    @mousedown="showRef = true"
                    @mouseup="showRef = false"
                    @mousemove="imageMove"></v-img>
        </v-col>

        <v-col class="single-page-input pa-1">
            <Check :data="data"
                    :id="systemId"
                    :siteviewId="data.remoteId"
                    :lastCheckDate="data.lastCheckDate"
                    :lastCheckUser="data.username"
                    :lastCheckUserMail="data.email"
                    :lastImageUpdate="data.lastUpdate"
                    :lastNote="data.note"
                    :server="failedImage ? 'FTP' : data.server"
                    :refDatetime="refDatetime"
                    v-on:edit-item-saved="itemSaved(arguments[0])"
                    v-on:no-scale="imageScale(arguments[0])"></Check>
        </v-col>

        <v-col class="single-page-right pa-0" id="camera-image-right">
            <v-img :src="showRef ? refImage : image"
                   ref="cameraPicture"
                   class="system-image-full"
                   @mousedown="showRef = true"
                   @mouseup="showRef = false"
                   @mousemove="imageMove"
                   @error="!showRef ? onImgError : () => true"></v-img>
        </v-col>
    </v-row>
</template>


<script>
import { mapActions } from 'vuex'
import moment from 'moment-timezone'
import axios from 'axios'
import { rest } from '../_helpers'
import { getToken } from '../_helpers/auth'
// import Check from '@/components/Check.vue'
const Check = () => import(
    /* webpackChunkName: "comp-check" */ '@/components/Check.vue'
)
const EXIF = require('exif-js')

export default {
    props: {
        propId: Number,
        siteviewId: Number,
    },

    data() {
        return {
            showRef: false,
            lastCheck: null,
            items: [],
            data: {},
            systemId: null,
            projectId: null,
            scaled: false,
            xPos: 'center',
            yPos: 'center',
            id: this.$route.params.id ? parseInt(this.$route.params.id, 10) : this.propId,
            lastCheckDate: null,
            lastCheckUser: null,
            lastCheckUserMail: null,
            lastUpdate: null,
            offset: 0,
            refDatetime: null,
            failedImage: false,
        }
    },

    components: {
        Check,
    },

    computed: {
        image() {
            let image = null // 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

            const now = Date.now()
            image = `${process.env.VUE_APP_REST_DOMAIN}/static/images/${this.systemId}.jpg?token=${getToken()}&ver=${now}`
            // `https://webcam.zeitraffer.org/ftp/${current}?ver=${Date.now()}`
            // 'https://s3.eu-central-1.amazonaws.com/mktsource1resized/' + this.data.systemId + '/original/full.jpg'
            // 'https://panodata.panomax.com/cams/' + this.data.systemId + '/recent_full.jpg'
            //  https://storage.mktimelapse.com/archive/21062301/recent_full.jpg  remoteId from siteview_data
            image = this.failedImage || !this.systemId
                ? 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
                : `${process.env.VUE_APP_REST_DOMAIN}/recent/${this.systemId}/full?token=${getToken()}&ver=${now}`

            return image
        },

        refImage() {
            let image = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

            const now = Date.now()
            image = `${process.env.VUE_APP_REST_DOMAIN}/static/images/refs/${this.systemId}.jpg?token=${getToken()}&ver=${now}`

            return image
        },

        exif() {
            const image = new Image()
            image.onload = function () {
                EXIF.getData(image, function () {
                    const make = EXIF.getTag(this, 'DateTimeOriginal')
                    const model = EXIF.getTag(this, 'Model')
                })
            }
            image.src = this.image

            return 1
        },
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
        }),

        onImgError() {
            this.failedImage = true
        },

        getChecks() {
            return rest.getChecks(this.id, this.offset)
                .then(item => {
                    this.data = item
                    this.systemId = this.data.id

                    this.showSpinner(false)
                })
                .then(res => {
                    this.getImageDate(this.refImage, 'refDatetime')
                })
        },

        getImageDate(imageUrl, tag) {
            const currentImage = new Image()
            currentImage.crossOrigin = 'Anonymous'
            currentImage.onload = () => {
                const obj = this
                EXIF.getData(currentImage, function () {
                    const exif = EXIF.getAllTags(this)
                    const exifDate = moment(exif.DateTime ? exif.DateTime : exif.DateTimeOriginal, 'YYYY:MM:DD HH:mm:ss')
                    if (exifDate.isValid()) {
                        obj[tag] = exifDate.valueOf()
                    } else {
                        axios.get(currentImage.src)
                            .then(resp => {
                                obj[tag] = moment(resp.headers['last-modified']).valueOf()
                            })
                    }
                })
            }

            currentImage.src = imageUrl
        },

        itemSaved(item) {
            if (item) {
                if (item[1] === 'skip') {
                    this.offset += 1
                }

                this.showSpinner(true)
                this.getChecks()
            }
        },

        imageScale(scaled) {
            const image = this.$refs.cameraPicture.$el.getElementsByClassName('v-image__image')[0]
            this.scaled = scaled ? scaled : !this.scaled

            if (this.scaled) {
                image.style.backgroundSize = 'unset'
            } else {
                image.style.backgroundSize = 'contain'
            }

            this.imagePos()
        },

        imagePos() {
            const image = this.$refs.cameraPicture.$el.getElementsByClassName('v-image__image')[0]
            if (this.scaled) {
                image.style.backgroundPosition = `${this.xPos} ${this.yPos}`
            } else {
                image.style.backgroundPosition = 'center center'
            }
        },

        imageMove(e) {
            if (this.scaled) {
                const mousePos = { x: e.clientX, y: e.clientY }
                const container = this.$refs.cameraPicture.$el
                const image = container.getElementsByClassName('v-image__image')[0]

                const xDir = (mousePos.x - container.getBoundingClientRect().x) - (container.offsetWidth / 2)
                const yDir = (mousePos.y - container.getBoundingClientRect().y) - (container.offsetHeight / 2)
                let xPerc = 50 + (xDir / (container.offsetWidth / 8)) * 50
                let yPerc = 50 + (yDir / (container.offsetHeight / 8)) * 50

                if (xPerc < 0) xPerc = 0
                if (xPerc > 100) xPerc = 100
                if (yPerc < 0) yPerc = 0
                if (yPerc > 100) yPerc = 100

                if (image.width < container.offsetWidth) xPerc = 50
                if (image.height < container.offsetHeight) yPerc = 50

                this.xPos = `${xPerc}%`
                this.yPos = `${yPerc}%`

                this.imagePos()
            }
        },

        keydown(event) {
            if (event.target.nodeName.toLowerCase() !== 'textarea') {
                if (event.key === 'r') {
                    event.preventDefault()
                    console.log('R')
                    this.showRef = true
                } else if (event.keyIdentifier === 'r'.charCodeAt(0)) {
                    event.preventDefault()
                    console.log('T')
                } else if (event.keyCode === 'r'.charCodeAt(0)) {
                    event.preventDefault()
                    console.log('E')
                }
            }
        },

        keyup(event) {
            if (event.target.nodeName.toLowerCase() !== 'textarea') {
                if (event.key === 'r') {
                    event.preventDefault()
                    console.log('R')
                    this.showRef = false
                } else if (event.keyIdentifier === 'r'.charCodeAt(0)) {
                    event.preventDefault()
                    console.log('T')
                } else if (event.keyCode === 'rr'.charCodeAt(0)) {
                    event.preventDefault()
                    console.log('E')
                }
            }
        },
    },

    created() {
        this.showSpinner(true)

        this.getChecks()

        window.addEventListener('keydown', this.keydown, { passive: true })
        window.addEventListener('keyup', this.keyup, { passive: true })
    },

    beforeDestroy() {
        window.removeEventListener('keydown', this.keydown, { passive: true })
        window.removeEventListener('keyup', this.keyup, { passive: true })
    },
}
</script>


<style lang="stylus" scoped>
    .single-page
        height 100%
        overflow-y auto

    .single-page .single-page-input
        max-width 40em
        height 100%
        overflow-y auto

    .system-image-full, .single-page-right
        height 100%

    .single-action-bar
        margin-bottom .5em

    .single-page-image
        display none

    @media screen and (max-width: 960px)
        .single-page .single-page-input
            width 100%
            height unset
            max-width unset
            flex-basis unset

        .single-page-image
            display block
            flex-basis unset
            padding 0

        .single-page-right
            display none
</style>

<style lang="stylus">
    .single-page-right .system-image-full .v-responsive__content
        width unset !important

    .single-page-right .system-image-full .v-image__image--cover
        background-size contain
        margin 0 auto
</style>
